import React, { useState, useEffect, useRef } from "react";
import pause from "../assets/icons/speaker.webp";
import play from "../assets/videos/playing.mp4";
import audio from "../assets/music/background-music.mp3";

const MusicPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false); // Set initial state to false
  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Function to lower the volume
  const lowerVolume = () => {
    if (audioRef.current) {
      audioRef.current.volume = 0.2; // Set the volume to half (0.5)
    }
  };

  useEffect(() => {
    // Preload the audio file
    audioRef.current.preload = "auto";

    // Add a click event listener to start playing audio when the user clicks anywhere
    const handleClick = () => {
      audioRef.current.play();
      setIsPlaying(true);
      // Remove the click event listener to prevent further automatic play
      document.removeEventListener("click", handleClick);
    };

    // Add the click event listener when the component mounts
    document.addEventListener("click", handleClick);

    // Clean up the click event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      const playPromise = audioRef.current.play();
      lowerVolume();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {})
          .catch((error) => {
            // Handle any playback errors here
          });
      }
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <div>
      <button onClick={handlePlayPause}>
        {isPlaying ? (
          <video
            src={play}
            muted
            autoPlay
            playsInline
            loop
            className="rounded-full bg-white drop-shadow-2xl w-10 lg:w-12 2xl:w-16 p-2 pointer-events-none"
          ></video>
        ) : (
          <img
            src={pause}
            alt="Pause"
            className="rounded-full bg-white drop-shadow-2xl w-10 lg:w-12 2xl:w-16 p-2 pointer-events-none"
          />
        )}
      </button>
      <audio ref={audioRef} src={audio} loop />
    </div>
  );
};

export default MusicPlayer;
