import React, { useEffect, useRef, useState } from "react";
import vision1 from "../assets/images/vision-1.webp";
import vision2 from "../assets/images/vision-2.webp";
import vision3 from "../assets/images/vision-3.webp";
import vision4 from "../assets/images/vision-4.webp";

const Vision = () => {
  const containerRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const [loadedCount, setLoadedCount] = useState(0);

  useEffect(() => {
    const images = containerRef.current.querySelectorAll("img");
    let maxHeight = 0;

    const handleImageLoad = () => {
      setLoadedCount((prevLoadedCount) => prevLoadedCount + 1);
    };

    images.forEach((image) => {
      image.addEventListener("load", handleImageLoad);
      if (image.height > maxHeight) {
        maxHeight = image.height;
      }
    });

    setMaxHeight(maxHeight);

    return () => {
      images.forEach((image) => {
        image.removeEventListener("load", handleImageLoad);
      });
    };
  }, [loadedCount]);

  return (
    <div
      ref={containerRef}
      className="mx-auto snap-y lg:py-4 sm:w-2/3 lg:w-1/2 overflow-y-scroll scrollbar-none"
      style={{ maxHeight: `${maxHeight}px` }}
    >
      {/* Scroll Image List */}
      {/* Images */}
      <div className="snap-start mb-10 lg:mb-16">
        <img src={vision1} alt="" className="rounded-2xl" />
      </div>
      <div className="snap-start my-10 lg:my-16">
        <img src={vision2} alt="" className="rounded-2xl" />
      </div>
      <div className="snap-start my-10 lg:my-16">
        <img src={vision3} alt="" className="rounded-2xl" />
      </div>
      <div className="snap-start mt-10 lg:mt-16">
        <img src={vision4} alt="" className="rounded-2xl" />
      </div>
    </div>
  );
};

export default Vision;
