import React, { useState } from "react";

const Categories = ({ imageUrl, text }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`sm:w-64 md:w-60 lg:w-72 2xl:w-68 rounded-2xl overflow-hidden w-[100%] m-auto md:ml-8 relative my-3 md:my-10 lg:my-0`}
      style={{
        transformOrigin: "center bottom", // Adjust the origin point
        transition: "transform 2s ease-in-out",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative overflow-hidden">
        {/* Image */}
        <img
          src={imageUrl}
          alt={text}
          className="w-full h-auto brightness-75 pointer-events-none"
          style={{
            transform: isHovered
              ? "scale(1.2)" // Zoom-in
              : "scale(1)", // Initial scale
            transition: "transform 2s ease-in-out",
          }}
        />
        {/* Text */}
        <div
          className={`absolute bottom-0 left-0 p-4 ${
            isHovered ? "scale-150" : ""
          }`}
          style={{
            transform: isHovered
              ? "scale(1.2)" // Zoom-in
              : "scale(1)", // Initial scale
            transformOrigin: "left bottom",
            transition: "transform 2s ease-in-out",
          }}
        >
          <p className="text-white text-lg font-bold">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Categories;
