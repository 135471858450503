import React from "react";

const Privacy = () => {
  return (
    <div className="sm:mr-5 text-sm font-normal text-subSeconday sm:text-base md:text-lg md:leading-8 lg:text-xl lg:leading-9 2xl:text-2xl 2xl:leading-10 grid grid-cols-1 gap-5">
      <p className="text-subPrimary">
        At Weom, we value your privacy and are committed to protecting your
        personal information. This Privacy Policy outlines how we collect, use,
        and safeguard the data you provide to us when visiting our website or
        using our services. Please take a moment to review the following
        information to understand our practices regarding your personal data.
      </p>
      <p>
        <span className="font-semibold text-subPrimary">
          Information Collection and Use:
        </span>{" "}
        When you visit our website or interact with our services, we may collect
        certain personally identifiable information, such as your name, email
        address, and other relevant details. We use this information to
        communicate with you, provide the requested services, and enhance your
        user experience.
      </p>
      <p>
        <span className="font-semibold text-subPrimary">Data Security:</span> We
        prioritize the security of your personal information and employ
        appropriate measures to protect it from unauthorized access, alteration,
        disclosure, or destruction. However, please note that no method of
        transmission or storage over the internet is completely secure, and we
        cannot guarantee absolute security.
      </p>
      <p>
        <span className="font-semibold text-subPrimary">Data Retention:</span>{" "}
        We retain your personal information for as long as necessary to fulfill
        the purposes outlined in this Privacy Policy, unless a longer retention
        period is required or permitted by law. When we no longer need your
        data, we will securely dispose of it.
      </p>
      <p>
        <span className="font-semibold text-subPrimary">
          Information Sharing:
        </span>{" "}
        We do not sell, trade, or transfer your personal information to third
        parties without your consent, except in cases where it is necessary for
        providing our services, complying with legal obligations, or protecting
        our rights, safety, or property.
      </p>
      <p>
        <span className="font-semibold text-subPrimary">
          Cookies and Tracking Technologies:
        </span>{" "}
        Weom may use cookies and similar tracking technologies to enhance your
        browsing experience, analyze website usage, and gather information about
        your preferences. You have the option to disable cookies through your
        browser settings, but this may impact certain features and functionality
        of our website.
      </p>
      <p>
        <span className="font-semibold text-subPrimary">
          Third-Party Links:
        </span>{" "}
        Our website may contain links to third-party websites or services that
        are not under our control. We are not responsible for the privacy
        practices or content of these external sites. We encourage you to review
        the privacy policies of those third parties before providing any
        personal information.
      </p>
      <p>
        <span className="font-semibold text-subPrimary">
          Updates to Privacy Policy:
        </span>{" "}
        We reserve the right to update or modify this Privacy Policy at any
        time. Any changes will be effective immediately upon posting the updated
        policy on our website. It is your responsibility to review this policy
        periodically for any updates.
      </p>
      <p>
        <span className="font-semibold text-subPrimary">Contact Us:</span> If
        you have any questions or concerns about our Privacy Policy or the
        handling of your personal information, please contact us at [contact
        email]. We will endeavor to address your inquiries promptly and to the
        best of our abilities.
      </p>
      <p>
        By using our website and services, you consent to the collection, use,
        and processing of your personal information as described in this Privacy
        Policy.
      </p>
    </div>
  );
};

export default Privacy;
