import React, { useEffect, useState } from "react";

const ScrollButton = ({ targetId, text }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const section = document.getElementById(targetId);
    if (section) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setIsActive(entry.isIntersecting);
          });
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        }
      );

      observer.observe(section);

      return () => observer.unobserve(section);
    }
  }, [targetId]);

  const scrollToSection = () => {
    const targetSection = document.getElementById(targetId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <button
      className={`scroll-button ${isActive ? "active text-secondary" : ""}`}
      onClick={scrollToSection}
    >
      {text}
    </button>
  );
};

export default ScrollButton;
