import React from 'react'
import divider from "../assets/elements/divider.webp";

const Divider = () => {
  return (
    <div className="w-64 sm:w-72 md:w-1/2 mx-auto relative overflow-x-hidden">
      {/* divider div */}
      <img src={divider} alt="divider" className="pointer-events-none" />
    </div>
  );
}

export default Divider;