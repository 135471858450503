import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo.webp";
import menu from "../assets/icons/hamburger.webp";
import heroBG from "../assets/elements/header-bg.webp";
import heroVid from "../assets/videos/hero-section.mp4";
import heroPoster from "../assets/posters/hero-poster.webp";
import star_icon from "../assets/icons/star.webp";
import arrow from "../assets/icons/arrow-button.webp";
import whatIsWeom from "../assets/images/what-is-weom.webp";
import playIcon from "../assets/icons/play.webp";
import communityIcon from "../assets/icons/community.webp";
import friendsIcon from "../assets/icons/friends.webp";
import shareIcon from "../assets/icons/share.webp";
import profileIcon from "../assets/icons/profile.webp";
import languageIcon from "../assets/icons/multi-lingual.webp";
import messageIcon from "../assets/icons/messaging.webp";
import modesIcon from "../assets/icons/modes.webp";
import phone1 from "../assets/elements/phone1.webp";
import phone2 from "../assets/elements/phone2.webp";
import ellipse from "../assets/elements/ellipse.webp";
import weomDiffVid from "../assets/videos/weom-is-different.mp4";
import weomDiffPoster from "../assets/posters/weom-is-different.webp";
import comingSoon from "../assets/images/coming-soon.webp";
import comingSoonBg from "../assets/elements/coming-soon-bg.webp";
import footerDivider from "../assets/elements/footer-divider.webp";
import facebook from "../assets/icons/facebook.webp";
import twitter from "../assets/icons/twitter.webp";
import instagram from "../assets/icons/instagram.webp";
import youtube from "../assets/icons/youtube.webp"
import linkedin from "../assets/icons/linkedin.webp";
import ScrollButton from "./ScrollButton";
import ScrollImage from "./ScrollImage";
import divider from "../assets/elements/divider.webp";
import Divider from "./Divider";
import arts from "../assets/images/arts.webp";
import astrology from "../assets/images/astrology.webp";
import yoga from "../assets/images/yoga-meditation.webp";
import festivals from "../assets/images/festivals.webp";
import culture from "../assets/images/culture-tradition.webp";
import travelling from "../assets/images/travelling.webp";
import bhakti from "../assets/images/bhakti.webp";
import science from "../assets/images/science-technology.webp";
import news from "../assets/images/news-politics.webp";
import kids from "../assets/images/kids.webp";
import Categories from "./Categories";
import MusicPlayer from "./MusicPlayer";
import Vision from "./Vision";
import notification from "../assets/images/notification.webp";
import tick from "../assets/icons/tick.webp"
import Privacy from "./Privacy";

const MyComponent = () => {
  // Menu hide or show
  const [show, setShow] = useState(false);
  const toggleMenu = () => {
    setShow(!show);
  };

  // modal to button CTA
  const [modalId, setModalId] = useState(null);

  const openModal = (id) => {
    setModalId(id);
  };

  const closeModal = () => {
    setModalId(null);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  // Form Data
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  // User Meta Data
  const [browserInfo, setBrowserInfo] = useState("");
  const [userOrg, setUserOrg] = useState("");
  const [userIp, setUserIp] = useState("");
  const [userTimezone, setUserTimezone] = useState("");
  const [userLoc, setUserLoc] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userPostal, setUserPostal] = useState("");

  useEffect(() => {
    // Fetching user's IP address and location using a public IP geolocation API
    fetch("https://ipinfo.io/json?token=ed3bc13b15570d")
      .then((response) => response.json())
      .then((data) => {
        const { city, region, country, postal, org, ip, loc, timezone } = data;
        setUserCountry(country);
        setUserOrg(org);
        setUserIp(ip);
        setUserLoc(loc);
        setUserTimezone(timezone);
        setUserCity(city);
        setUserState(region);
        setUserPostal(postal)
      })
      .catch((error) => {
        console.error("Error fetching IP/location data:", error);
      });

    // Get browser information
    const browserName = navigator.userAgent;
    setBrowserInfo(browserName);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // API Endpoint to insert data into Google Sheets
    const SHEETS_API_ENDPOINT =
      "https://sheets.googleapis.com/v4/spreadsheets/1qKxEqDUHBXnWYpm49rRP0GoOS5PvdT0oBUNJBnVSHPk/values/A1:append?key=AIzaSyCLiab6Z_GEuOHApcXGzzPEcnKAHGxqogY";

    // Form data to be inserted into Google Sheets
    const data = [
      [
        formData.name,
        formData.email,
        formData.phone,
        browserInfo,
        userCity,
        userState,
        userCountry,
        userPostal,
        userIp,
        userOrg,
        userTimezone,
        userLoc,
      ],
    ];

    // API request configuration
    const config = {
      method: "POST",
      body: JSON.stringify({
        values: data,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Making the API request using fetch
    fetch(SHEETS_API_ENDPOINT, config)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data inserted successfully!", data);
        openModal(5);
        // Optionally, you can perform any action after successful data insertion.
      })
      .catch((error) => {
        console.error("Error inserting data:", error);
        openModal(6);
        // Handle errors if any.
      });

    console.log("Form Data:", formData);
    console.log("Data to be sent:", data);
  };

  return (
    <>
      <header className="mb-20">
        <div className="fixed top-0 left-0 right-0 z-50 bg-white bg-opacity-80 backdrop-blur-md">
          <nav className="flex items-center justify-between lg:justify-around py-3 lg:py-4 px-6 lg:px-0">
            {/* Logo */}
            <ScrollImage
              targetId="home"
              imageUrl={logo}
              className="w-20 h-auto md:w-28 lg:w-28 xl:w-32"
              alt="weom_logo"
            />
            {/* Full-screen mobile menu */}
            <div
              className={`${
                show ? "block" : "hidden"
              } lg:hidden fixed z-50 top-0 left-0 right-0 w-screen h-screen bg-white bg-opacity-[0.98]`}
            >
              {/* Close (X) button */}
              <button
                className="absolute top-3 right-5 text-3xl font-bold"
                onClick={toggleMenu}
              >
                &times;
              </button>
              <ul className="flex flex-col items-center justify-center h-full space-y-7 text-lg md:text-2xl text-subSeconday">
                <li>
                  <div onClick={toggleMenu}>
                    <ScrollButton targetId="home" text="Home" />
                  </div>
                </li>
                <li>
                  <div onClick={toggleMenu}>
                    <ScrollButton targetId="whatIsWeom" text="What is weom" />
                  </div>
                </li>
                <li>
                  <div onClick={toggleMenu}>
                    <ScrollButton
                      targetId="weomFeatures"
                      text="Weom features"
                    />
                  </div>
                </li>
                <li>
                  <div onClick={toggleMenu}>
                    <ScrollButton
                      targetId="contentCategories"
                      text="Content categories"
                    />
                  </div>
                </li>
                <li>
                  <div onClick={toggleMenu}>
                    <ScrollButton
                      targetId="weomIsDifferent"
                      text="How weom is different"
                    />
                  </div>
                </li>
                <li>
                  <div onClick={toggleMenu}>
                    <ScrollButton targetId="ourVision" text="Our vision" />
                  </div>
                </li>
              </ul>
            </div>
            {/* Menu contents */}
            <div className="flex items-center gap-4 lg:gap-10 xl:gap-5 xl:-mx-10">
              {/* Regular menu */}
              <ul className="hidden lg:flex lg:text-sm lg:space-x-6 text-subSeconday font-normal 2xl:text-base xl:space-x-8 xl:mx-10 2xl:space-x-12">
                <li>
                  <ScrollButton targetId="home" text="Home" />
                </li>
                <li>
                  <ScrollButton targetId="whatIsWeom" text="What is weom" />
                </li>
                <li>
                  <ScrollButton targetId="weomFeatures" text="Weom features" />
                </li>
                <li>
                  <ScrollButton
                    targetId="contentCategories"
                    text="Content categories"
                  />
                </li>
                <li>
                  <ScrollButton
                    targetId="weomIsDifferent"
                    text="How weom is different"
                  />
                </li>
                <li>
                  <ScrollButton targetId="ourVision" text="Our vision" />
                </li>
              </ul>
              {/* Join Waitlist Button */}
              <button
                className="btn text-xs md:text-sm px-3 py-2 md:px-5 text-white font-bold 2xl:text-base cursor-pointer"
                onClick={() => openModal(1)}
              >
                Join The Waitlist
              </button>
              {/* Mobile Menu */}
              <button className="block lg:hidden" onClick={toggleMenu}>
                <img src={menu} alt="" className="w-7 pointer-events-none" />
              </button>
            </div>
          </nav>
        </div>
      </header>

      <main className="mx-8 md:mx-0 lg:mx-10 xl:px-16 2xl:px-28">
        {/* Home Section */}
        <div id="home" className="">
          {/* Hero Section */}
          <div className="flex items-center justify-center py-3 xl:py-0 xl:pt-4 px-3 lg:px-5 relative overflow-hidden -mx-8 sm:-mx-0 lg:-mx-20">
            {/* Background Image */}
            <img
              src={heroBG}
              alt="hero-bg"
              className="absolute min-w-[1200px] lg:min-w-[1200px] xl:min-w-[1500px] 2xl:min-w-[1850px] 2xl:-top-5 pointer-events-none overflow-hidden"
            />
            {/* Video */}
            <div className="relative">
              <video
                src={heroVid}
                poster={heroPoster}
                muted
                autoPlay
                playsInline
                loop
                className="w-44 h-auto lg:w-48 xl:w-48 2xl:w-56 2xl:mt-10 pointer-events-none rounded-[2.2rem]"
              ></video>
            </div>
          </div>
          <div>
            <div className="flex items-center justify-center gap-2 lg:gap-4">
              <img
                src={star_icon}
                alt=""
                className="w-7 lg:w-10 xl:w-12 pointer-events-none"
              />
              <h1 className="text-3xl font-bellota relative animate-fade-in md:text-4xl lg:text-6xl">
                Experience Freedom
              </h1>
            </div>
            <div className="m-auto max-w-lg">
              <p className="text-md font-light my-4 mx-3 text-subSeconday text-center leading-8 md:text-xl md:leading-8 lg:text-2xl lg:leading-10">
                Embrace Sanatana Dharma with our one-of-a-kind Social Media
                Platform
              </p>
            </div>
            <div className="flex justify-center items-center">
              <ScrollImage
                targetId="whatIsWeom"
                imageUrl={arrow}
                className="w-16 relative animate-bounce mb-16"
                alt="go_down"
              />
            </div>
          </div>
        </div>
        <Divider />
        {/* About / What is Weom Section */}
        <div
          className="py-12 md:py-16 lg:my-0 xl:py-20 2xl:py-24"
          id="whatIsWeom"
        >
          <div className="flex items-center justify-center pb-3 lg:pb-4 px-3 lg:px-5">
            <div className="m-auto max-w-lg md:max-w-2xl 2xl:max-w-3xl text-center">
              <h1 className="text-3xl font-bellota text-primary relative md:text-4xl lg:text-5xl 2xl:text-6xl">
                What is weom
              </h1>
              <p className="text-md font-normal my-8 text-subPrimary leading-8 md:text-xl md:leading-8 md:mx-12 2xl:text-2xl 2xl:leading-10">
                A unique & safe social media that enhances the positives of a
                social media while eliminating the negatives aspects
              </p>
            </div>
          </div>
          {/* Flex on desktop */}
          <div className="sm:flex sm:gap-10 xl:gap-20 items-center justify-center py-3 lg:py-4 px-3 lg:px-0">
            {/* Image */}
            <div
              className="sm:flex-auto sm:w-64 md:w-1/2 rounded-2xl overflow-hidden w-[100%] m-auto md:ml-8"
              style={{ position: "relative" }}
            >
              <img
                src={whatIsWeom}
                alt=""
                className="opacity-0 pointer-events-none"
              />
              <div
                className="absolute inset-0 bg-no-repeat bg-center bg-cover animate-zoom"
                style={{ backgroundImage: `url(${whatIsWeom})` }}
              ></div>
            </div>
            {/* List */}
            <div className="text-md sm:flex-auto sm:w-80 md:w-1/2 font-semibold my-8 text-subPrimary md:text-lg lg:text-xl xl:text-2xl md:mr-8 2xl:text-3xl">
              <div className="flex items-center justify-start gap-7 py-3 lg:py-5 xl:py-8 lg:px-5">
                <img
                  src={playIcon}
                  alt="play"
                  className="w-8 lg:w-12 pointer-events-none"
                />
                <p>Watch interest based content in your preferred language</p>
              </div>
              <div className="flex items-center justify-start gap-7 py-3 lg:py-5 xl:py-8 lg:px-5">
                <img
                  src={communityIcon}
                  alt="play"
                  className="w-8 lg:w-12 pointer-events-none"
                />
                <p>Join and engage in communities</p>
              </div>
              <div className="flex items-center justify-start gap-7 py-3 lg:py-5 xl:py-8 lg:px-5">
                <img src={friendsIcon} alt="play" className="w-8 lg:w-12" />
                <p>Add & chat with friends & like-minded people</p>
              </div>
              <div className="flex items-center justify-start gap-7 py-3 lg:py-5 xl:py-8 lg:px-5">
                <img src={shareIcon} alt="play" className="w-8 lg:w-12" />
                <p>Share your thoughts & interact with friends & communities</p>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        {/* Features Section */}
        <div
          className="py-12 md:py-16 lg:my-0 xl:py-20 2xl:py-24"
          id="weomFeatures"
        >
          <div className="flex items-center justify-center pb-3 lg:pb-4 px-3 lg:px-5">
            <div className="m-auto max-w-lg md:max-w-2xl 2xl:max-w-4xl text-center">
              <h1 className="text-3xl font-bellota text-primary relative md:text-4xl lg:text-5xl 2xl:text-6xl">
                Discover the features of weom
              </h1>
              <p className="text-md font-normal my-8 text-subPrimary leading-8 md:text-xl md:leading-8 md:mx-12 lg:mx-0 2xl:text-2xl 2xl:leading-10 2xl:max-w-3xl">
                Enjoy the world of weom by advanced features enhancing your
                social media & content consuming experience
              </p>
            </div>
          </div>
          {/* Grid */}
          <div className="sm:hidden">
            <div className="relative ">
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-center bg-no-repeat bg-contain -m-2"
                style={{ backgroundImage: `url(${ellipse})` }}
              ></div>
              {/* Video */}
              <div className="flex items-center justify-center mb-10 relative z-10">
                <img
                  src={phone1}
                  alt=""
                  className="w-28 animate-up-phone relative"
                />
                <img
                  src={phone2}
                  alt=""
                  className="w-28 -ml-3 animate-down-phone relative"
                />
              </div>
            </div>
            <div className="grid grid-cols-6 gap-10 place-items-center items-start">
              <div className="col-start-1 col-end-4 font-semibold text-sm text-subPrimary sm:text-md md:text-lg lg:text-xl xl:text-2xl md:mr-8">
                <div>
                  <img src={playIcon} alt="play" className="w-5 lg:w-12 my-5" />
                  <p className="-mt-4">Interest based content</p>
                </div>
                <div>
                  <img
                    src={languageIcon}
                    alt="profile"
                    className="w-5 lg:w-12 my-5"
                  />
                  <p className="-mt-4">Multi-lingual content</p>
                </div>
                <div>
                  <img
                    src={messageIcon}
                    alt="message"
                    className="w-5 lg:w-12 my-5"
                  />
                  <p className="-mt-4">Direct messaging and & group chat</p>
                </div>
              </div>
              <div className="col-end-7 col-span-3 font-semibold text-sm text-subPrimary sm:text-md md:text-lg lg:text-xl xl:text-2xl md:mr-8">
                <div>
                  <img
                    src={communityIcon}
                    alt="community"
                    className="w-5 lg:w-12 my-5"
                  />
                  <p className="-mt-4">Community channels</p>
                </div>
                <div>
                  <img
                    src={profileIcon}
                    alt="language"
                    className="w-5 lg:w-12 my-5"
                  />
                  <p className="-mt-4">Profile Customization</p>
                </div>
                <div>
                  <img
                    src={modesIcon}
                    alt="mode"
                    className="w-5 lg:w-12 my-5"
                  />
                  <p className="-mt-4">Light & dark mode</p>
                </div>
              </div>
            </div>
          </div>
          {/* Desktop Spcific */}
          <div className="hidden sm:grid grid-cols-3 gap-2 lg:gap-10 font-semibold place-items-center text-sm text-subPrimary sm:text-md md:text-lg lg:text-xl xl:text-2xl">
            <div className="col-start-1 ml-24 md:ml-20 lg:ml-32 w-32 md:w-40 lg:w-48 xl:w-60">
              <img
                src={playIcon}
                alt="play"
                className="w-6 md:w-8 lg:w-12 my-5"
              />
              <p className="-mt-4">Interest based content</p>
            </div>
            <div className="col-start-2 row-start-1 row-end-4">
              <div className="relative">
                <div
                  className="absolute inset-0 z-0 bg-center bg-no-repeat bg-contain -m-7"
                  style={{ backgroundImage: `url(${ellipse})` }}
                ></div>
                <div className="flex pointer-events-none">
                  <img
                    src={phone1}
                    alt=""
                    className="sm:w-28 md:w-36 lg:w-40 xl:w-44 2xl:w-60 -ml-5 animate-up-phone lg:animate-up relative"
                  />
                  <img
                    src={phone2}
                    alt=""
                    className="sm:w-28 md:w-36 lg:w-40 xl:w-44 2xl:w-60 sm:-ml-3 lg:-ml-4 xl:-ml-5 2xl:-ml-6 animate-down-phone lg:animate-down relative"
                  />
                </div>
              </div>
            </div>
            <div className="col-start-3 lg:mr-20 w-32 md:w-40 lg:w-48 xl:w-60">
              <img
                src={profileIcon}
                alt="language"
                className="w-6 md:w-8 lg:w-12 my-5"
              />
              <p className="-mt-4">Profile Customization</p>
            </div>
            <div className="col-start-1 lg:mr-20 w-32 md:w-40 lg:w-48 xl:w-60">
              <img
                src={communityIcon}
                alt="community"
                className="w-6 md:w-8 lg:w-12 my-5"
              />
              <p className="-mt-4">Community channels</p>
            </div>
            <div className="col-start-3 ml-24 md:ml-20 lg:ml-32 w-32 md:w-40 lg:w-48 xl:w-60">
              <img
                src={messageIcon}
                alt="message"
                className="w-6 md:w-8 lg:w-12 my-5"
              />
              <p className="-mt-4">Direct messaging and & group chat</p>
            </div>
            <div className="col-start-1 ml-24 md:ml-20 lg:ml-32 w-32 md:w-40 lg:w-48 xl:w-60">
              <img
                src={languageIcon}
                alt="profile"
                className="w-6 md:w-8 lg:w-12 my-5"
              />
              <p className="-mt-4">Multi-lingual content</p>
            </div>
            <div className="col-start-3 lg:mr-20 w-32 md:w-40 lg:w-48 xl:w-60">
              <img
                src={modesIcon}
                alt="mode"
                className="w-6 md:w-8 lg:w-12 my-5"
              />
              <p className="-mt-4">Light & dark mode</p>
            </div>
          </div>
        </div>
        <Divider />
        {/* Categories Section */}
        <div
          className="py-12 md:py-16 lg:my-0 xl:py-20 2xl:py-24"
          id="contentCategories"
        >
          <div className="flex items-center justify-center pb-3 lg:pb-4 px-3 lg:px-5">
            <div className="m-auto max-w-lg md:max-w-3xl 2xl:max-w-4xl text-center">
              <h1 className="text-3xl font-bellota text-primary relative md:text-4xl lg:text-5xl 2xl:text-6xl">
                Content Categories
              </h1>
              <p className="text-md font-normal my-8 text-subPrimary leading-8 md:text-xl md:leading-8 md:mx-12 lg:mx-0 2xl:text-2xl 2xl:leading-10">
                While our main emphasis lies in promoting Sanatana Dharma
                values, we also offer a diverse range of categories that cater
                to the interests of everyone
              </p>
            </div>
          </div>
          {/* Flex on desktop */}
          <div className="flex items-center justify-center lg:grid lg:grid-cols-1">
            <div className="lg:flex items-center justify-center lg:py-4 px-2 md:px-0 lg:px-0 2xl:-mx-16">
              {/* Yoga & Meditation */}
              <Categories imageUrl={yoga} text="Yoga & Meditation" />
              {/* Culture & Tradition */}
              <Categories imageUrl={culture} text="Culture & Tradition" />
              {/* Festivals */}
              <Categories imageUrl={festivals} text="Festivals" />
              {/* Travelling */}
              <Categories imageUrl={travelling} text="Travelling" />
              {/* Arts */}
              <Categories imageUrl={arts} text="Arts" />
            </div>
            <div className="lg:flex items-center justify-center lg:py-4 px-2 md:px-0 lg:px-0 2xl:-mx-16">
              {/* Astrology */}
              <Categories imageUrl={astrology} text="Astrology" />
              {/* Bhakti */}
              <Categories imageUrl={bhakti} text="Bhakti" />
              {/* Science & Technology */}
              <Categories imageUrl={science} text="Science & Technology" />
              {/* News & Politics */}
              <Categories imageUrl={news} text="News & Politics" />
              {/* Kids */}
              <Categories imageUrl={kids} text="Kids" />
            </div>
          </div>
        </div>
        <Divider />
        {/* HowDifferent Section */}
        <div
          className="py-12 md:py-16 lg:my-0 xl:py-20 2xl:py-24"
          id="weomIsDifferent"
        >
          <div className="flex items-center justify-center pb-3 lg:pb-4 px-3 lg:px-5">
            <div className="m-auto max-w-lg md:max-w-2xl 2xl:max-w-3xl text-center">
              <h1 className="text-3xl font-bellota text-primary relative md:text-4xl lg:text-5xl 2xl:text-6xl -mx-4">
                How weom is differrent
              </h1>
              <p className="text-md font-normal my-8 text-subPrimary leading-8 md:text-xl md:leading-8 md:mx-12 2xl:text-2xl 2xl:leading-10">
                Weom is designed to spread the values of Sanatana Dharma while
                being the safest social media platform for everyone
              </p>
            </div>
          </div>
          {/* Flex on desktop */}
          <div className="flex items-center justify-center -mx-5 md:-mx-0">
            <video
              src={weomDiffVid}
              poster={weomDiffPoster}
              muted
              playsInline
              autoPlay
              className="w-full md:w-2/3 lg:w-1/2 h-auto pointer-events-none rounded-[2.2rem]"
            ></video>
          </div>
        </div>
        <Divider />
        {/* OurVision Section */}
        <div
          className="py-12 md:py-16 lg:my-0 xl:py-20 2xl:py-24"
          id="ourVision"
        >
          <div className="flex items-center justify-center pb-3 lg:pb-12 px-3 lg:px-5">
            <div className="m-auto max-w-lg md:max-w-2xl 2xl:max-w-3xl text-center">
              <h1 className="text-3xl font-bellota text-primary relative md:text-4xl lg:text-5xl 2xl:text-6xl -mx-4">
                Our Vision
              </h1>
            </div>
          </div>
          <div className="lg:flex lg:my-8">
            <div className="grid place-items-center text-center md:mx-24 lg:mx-10 lg:text-left lg:place-items-start lg:place-content-center lg:w-2/3">
              <p className="text-lg font-bold mt-8 lg:mt-0 lg:mb-2 text-subPrimary leading-8 md:text-2xl md:leading-8 md:mx-12 2xl:text-4xl 2xl:leading-10 my-1">
                Embracing Eternal Wisdom
              </p>
              <p className="text-md font-light mb-8 lg:mb-0 text-subSeconday leading-6 md:text-xl md:leading-9 sm:mx-24 lg:mx-12 2xl:text-2xl 2xl:leading-10 xl:w-3/4 2xl:w-2/3">
                Unlocking the eternal wisdom of Sanatana Dharma, our app fosters
                global unity, empowering individuals to explore, practice, and
                lead purposeful lives guided by timeless principles.
              </p>
            </div>
            <Vision />
          </div>
          {/* divider div */}
          <div className="my-10 xl:my-32 2xl:my-40"></div>
        </div>
        {/* ComingSoon Section */}
        <div>
          <div
            className="bg-no-repeat bg-cover bg-center rounded-2xl pt-5 px-5 sm:mx-28 md:mx-40 lg:mx-0 lg:flex"
            style={{ backgroundImage: `url(${comingSoonBg})` }}
          >
            <div className="flex py-3 lg:py-12 px-3 lg:px-5 lg:items-start xl:mx-16 2xl:mx-28 lg:w-2/5">
              <div className="m-auto max-w-lg md:max-w-2xl 2xl:max-w-3xl text-center lg:text-left">
                <h1 className="text-2xl font-bellota font-normal text-black relative md:text-3xl 2xl:text-4xl -mx-4 my-2 lg:-mx-0 lg:mb-5">
                  Weom is Coming Soon
                </h1>
                <p className="text-md font-light mb-7 mx-5 text-subSeconday lg:text-subPrimary md:text-lg md:leading-8 2xl:text-xl 2xl:leading-9 md:mx-12 lg:mx-0">
                  Weom is currently in the early stage, subscribe to us to get
                  updates of our progress.
                </p>
                {/* Join Waitlist Button */}
                <button
                  className="text-sm md:text-lg text-white font-bold 2xl:text-lg bg-primary rounded-full py-2 px-10 lg:px-7 md:py-3 mb-10 cursor-pointer"
                  onClick={() => openModal(1)}
                >
                  Join The Waitlist
                </button>
              </div>
            </div>
            <div className="px-3 lg:px-0 xl:mx-24 2xl:mx-28 lg:mt-20">
              {/* Image */}
              <div className="rounded-2xl w-2/3 lg:w-72 2xl:w-80 m-auto relative">
                <img src={comingSoon} alt="" className="" />
              </div>
            </div>
          </div>
          {/* divider div */}
          <div className="sm:hidden my-12 md:my-20 w-full sm:w-72 md:w-1/2 mx-auto relative overflow-x-hidden">
            <img src={divider} alt="divider" />
          </div>
          <div className="hidden sm:block sm:mt-12 md:mt-28 lg:mt-36 mb-12 mx-10 md:mx-24 lg:mx-10">
            <img src={footerDivider} alt="divider" />
          </div>
        </div>
        {/* Modals */}
        {/* Modal for Form */}
        {modalId === 1 && (
          <div
            className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            onClick={closeModal}
          >
            <div
              className="bg-white p-8 fixed -bottom-5 sm:relative w-screen sm:h-auto sm:w-5/6 md:w-5/6 lg:w-3/6 2xl:w-2/5 rounded-2xl overflow-y-hidden"
              onClick={handleModalClick}
            >
              <button
                className="mt-4 px-4 md:py-2 text-black rounded-md text-3xl sm:text-4xl font-light absolute top-3 sm:top-0 right-2 sm:right-5"
                onClick={closeModal}
              >
                &times;
              </button>
              <h3 className="text-lg md:text-2xl lg:text-3xl font-normal text-primary mb-4 w-4/5 sm:w-3/5">
                8000+ People already on waitlist
              </h3>
              <div className="grid grid-cols-1 sm:flex justify-center items-start">
                <div className="sm:hidden">
                  {/* icon mobile view */}
                  <img
                    src={notification}
                    alt="notification"
                    className="w-36 mx-auto"
                  />
                  <p className="text-base lg:text-base font-light text-black">
                    Subscribe to get updates on the exciting journey of Weom!
                  </p>
                </div>
                <div className="sm:w-2/3">
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 place-items-start gap-5 my-5">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Full name"
                        className="border border-[#DCDCDC] p-2 md:p-3 bg-[#F8F8F8] rounded-lg w-full sm:w-5/6"
                        required
                      />
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email ID"
                        className="border border-[#DCDCDC] p-2 md:p-3 bg-[#F8F8F8] rounded-lg w-full sm:w-5/6"
                        required
                      />
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Mobile number"
                        maxLength={10}
                        className="border border-[#DCDCDC] p-2 md:p-3 bg-[#F8F8F8] rounded-lg w-full sm:w-5/6"
                        required
                      />
                      <button
                        className="btn text-sm md:text-base lg:text-lg font-bold sm:px-3 py-3 md:px-7 mt-2 w-full sm:w-36 text-white cursor-pointer"
                        type="submit"
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
                <div className="hidden sm:block sm:w-1/3">
                  <img src={notification} alt="notification" />
                  <p className="text-sm lg:text-base font-light text-subPrimary">
                    Subscribe to get updates on the exciting journey of Weom!
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Claims Modal */}
        {modalId === 2 && (
          <div
            className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            onClick={closeModal}
          >
            <div
              className="bg-white px-8 pt-8 fixed -bottom-5 sm:relative h-4/6 w-screen sm:h-auto sm:w-5/6 md:w-5/6 lg:w-3/6 2xl:w-3/6 rounded-2xl overflow-y-hidden"
              onClick={handleModalClick}
            >
              <button
                className="mt-4 px-4 md:py-2 text-black rounded-md text-3xl sm:text-4xl font-light absolute top-3 sm:top-0 right-2 sm:right-5"
                onClick={closeModal}
              >
                &times;
              </button>
              <h1 className="text-xl md:text-2xl lg:text-3xl mb-8 font-semibold text-primary">
                Claims policy
              </h1>
              <div className="h-[100%] sm:h-64 md:h-96 2xl:h-[550px] overflow-y-scroll mb-5 pb-28 sm:pb-10 scrollbar-none">
                <Privacy />
              </div>
            </div>
          </div>
        )}
        {/* Privacy Modal */}
        {modalId === 3 && (
          <div
            className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            onClick={closeModal}
          >
            <div
              className="bg-white px-8 pt-8 fixed -bottom-5 sm:relative h-4/6 w-screen sm:h-auto sm:w-5/6 md:w-5/6 lg:w-3/6 2xl:w-3/6 rounded-2xl overflow-y-hidden"
              onClick={handleModalClick}
            >
              <button
                className="mt-4 px-4 md:py-2 text-black rounded-md text-3xl sm:text-4xl font-light absolute top-3 sm:top-0 right-2 sm:right-5"
                onClick={closeModal}
              >
                &times;
              </button>
              <h1 className="text-xl md:text-2xl lg:text-3xl mb-8 font-semibold text-primary">
                Privacy policy
              </h1>
              <div className="h-[100%] sm:h-64 md:h-96 2xl:h-[550px] overflow-y-scroll mb-5 pb-28 sm:pb-10 scrollbar-none">
                <Privacy />
              </div>
            </div>
          </div>
        )}
        {/* Terms Modal */}
        {modalId === 4 && (
          <div
            className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            onClick={closeModal}
          >
            <div
              className="bg-white px-8 pt-8 fixed -bottom-5 sm:relative h-4/6 w-screen sm:h-auto sm:w-5/6 md:w-5/6 lg:w-3/6 2xl:w-3/6 rounded-2xl overflow-y-hidden"
              onClick={handleModalClick}
            >
              <button
                className="mt-4 px-4 md:py-2 text-black rounded-md text-3xl sm:text-4xl font-light absolute top-3 sm:top-0 right-2 sm:right-5"
                onClick={closeModal}
              >
                &times;
              </button>
              <h1 className="text-xl md:text-2xl lg:text-3xl mb-8 font-semibold text-primary">
                Terms & conditions
              </h1>
              <div className="h-[100%] sm:h-64 md:h-96 2xl:h-[550px] overflow-y-scroll mb-5 pb-28 sm:pb-10 scrollbar-none">
                <Privacy />
              </div>
            </div>
          </div>
        )}
        {/* Subscription success */}
        {modalId === 5 && (
          <div
            className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            onClick={closeModal}
          >
            <div
              className="bg-white p-8 fixed sm:relative w-5/6 sm:h-auto sm:w-auto rounded-2xl grid grid-cols-1 gap-5"
              onClick={handleModalClick}
            >
              <div className="sm:flex justify-center items-center gap-5">
                <img src={tick} alt="tick" className="w-10 mx-auto" />
                <p className="text-center mt-5 sm:mt-0 font-light sm:font-normal">
                  You've subscribed for the updates!
                </p>
              </div>
              <div className="relative sm:mx-auto">
                <button
                  className="btn text-sm md:text-base lg:text-lg font-bold px-3 w-full sm:w-20 py-1 md:px-5 sm:mt-2 text-white cursor-pointer"
                  onClick={closeModal}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Subscription error */}
        {modalId === 6 && (
          <div
            className="fixed z-50 inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            onClick={closeModal}
          >
            <div
              className="bg-white p-8 fixed sm:relative w-5/6 sm:h-auto sm:w-auto rounded-2xl grid grid-cols-1 gap-5"
              onClick={handleModalClick}
            >
              <div className="sm:flex justify-center items-center gap-5">
                <img src={tick} alt="tick" className="w-10 mx-auto" />
                <p className="text-center mt-5 sm:mt-0 font-light sm:font-normal">
                  Oops! Some error occured. <br /> Please try again later
                </p>
              </div>
              <div className="relative sm:mx-auto">
                <button
                  className="btn text-sm md:text-base lg:text-lg font-bold px-3 w-full sm:w-20 py-1 md:px-5 sm:mt-2 text-white cursor-pointer"
                  onClick={closeModal}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Comment the below during development uncomment before making it live */}
        <div className="fixed z-20 bottom-10 right-5 md:right-20">
          <MusicPlayer />
        </div>
      </main>

      {/* Footer Section */}
      <footer className="px-6 lg:px-0">
        <div className="flex items-center justify-between gap-5 lg:gap-15 lg:py-3 px-3 lg:px-5 md:mx-16 xl:mx-32 2xl:mx-40">
          <div className="w-40 md:w-2/5 lg:w-1/3">
            {/* Logo */}
            <ScrollImage
              targetId="home"
              imageUrl={logo}
              className="w-20 h-auto md:w-28 lg:w-28 xl:w-40 pointer-events-none mb-3"
              alt="weom_logo"
            />
            <p className="text-xs font-light text-subSeconday leading-5 md:text-sm md:leading-7 xl:text-lg xl:leading-8 lg:w-4/5 xl:w-full 2xl:w-2/3">
              Unite, Share, and Explore the Essence of Sanatana Dharma in a
              Digital Community.
            </p>
          </div>
          {/* Desktop social menu */}
          <div className="hidden md:block md:w-2/5 lg:w-1/3">
            <div className="flex gap-5 lg:gap-12 justify-center">
              <a href="https://www.facebook.com/HeyGrowzilla/" target="blank">
                <img src={facebook} alt="" className="w-5 lg:w-8" />
              </a>
              <a
                href="https://www.linkedin.com/company/heygrowzilla/"
                target="black"
              >
                <img src={linkedin} alt="" className="w-5 lg:w-8" />
              </a>
              <a href="https://www.instagram.com/heygrowzilla/" target="black">
                <img src={instagram} alt="" className="w-5 lg:w-8" />
              </a>
              <a href="https://twitter.com/heygrowzilla" target="black">
                <img src={twitter} alt="" className="w-5 lg:w-8" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCo3ckNCxQy-ZWYxi4DEGlCQ"
                target="black"
              >
                <img src={youtube} alt="" className="w-5 lg:w-8" />
              </a>
            </div>
          </div>
          <div
            className="grid grid-cols-1 place-items-end justify-end lg:flex font-light text-black 
          gap-4 md:gap-5 lg:gap-8 text-sm md:text-md lg:text-xl 2xl:text-2xl md:w-1/5 lg:w-1/3"
          >
            <button onClick={() => openModal(2)}>Claim</button>
            <button onClick={() => openModal(3)}>Privacy</button>
            <button onClick={() => openModal(4)}>Terms</button>
          </div>
        </div>
        {/* Mobile social menu */}
        <div className="md:hidden flex items-center justify-center my-8">
          <div className="flex gap-8">
            <a href="https://www.facebook.com/HeyGrowzilla/" target="blank">
              <img src={facebook} alt="" className="w-5 lg:w-8" />
            </a>
            <a
              href="https://www.linkedin.com/company/heygrowzilla/"
              target="black"
            >
              <img src={linkedin} alt="" className="w-5 lg:w-8" />
            </a>
            <a href="https://www.instagram.com/heygrowzilla/" target="black">
              <img src={instagram} alt="" className="w-5 lg:w-8" />
            </a>
            <a href="https://twitter.com/heygrowzilla" target="black">
              <img src={twitter} alt="" className="w-5 lg:w-8" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCo3ckNCxQy-ZWYxi4DEGlCQ"
              target="black"
            >
              <img src={youtube} alt="" className="w-5 lg:w-8" />
            </a>
          </div>
        </div>
        <div className="flex mb-5 px-3 lg:px-5 md:mx-16 lg:mx-32">
          <p className="text-xs font-light text-center md:my-3 lg:my-1 text-subSeconday leading-5 md:text-sm md:leading-7 xl:text-lg xl:leading-8 mx-auto">
            © 2023 Growzilla Internet PVT LTD. All rights reserved
          </p>
        </div>
        <div className="h-12 hidden md:block xl:h-16 2xl:h-20"></div>
      </footer>
    </>
  );
};

export default MyComponent;
