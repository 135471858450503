import React from 'react'

const ScrollImage = ({ targetId, imageUrl, className, alt }) => {
  const scrollToSection = () => {
    const targetSection = document.getElementById(targetId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <button onClick={scrollToSection}>
      <img src={imageUrl} alt={alt} className={className} />{" "}
    </button>
  );
};

export default ScrollImage